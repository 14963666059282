import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AvatarComponent } from '@ebf-libs/sdk/platform/avatar/lib/view/avatar/avatar.component';
import { AvatarModule } from 'primeng/avatar';

@NgModule({
  declarations: [AvatarComponent],
  imports: [CommonModule, AvatarModule],
  exports: [AvatarComponent],
})
export class EbfAvatarModule {}
