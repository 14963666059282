import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ebf-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnInit {
  @Input()
  public avatar: string;
  @Input()
  public fullName: string;
  @Input()
  public size: 'normal' | 'large' | 'xlarge' = 'large';
  @Input()
  public styleClass: string;
  @Input()
  public shape: 'circle' | 'square' = 'circle';

  public initials: string;

  public ngOnInit(): void {
    if (!this.avatar && this.fullName) {
      this.initials = this.getInitials();
    }
  }

  private getInitials(): string {
    const nameParts: string[] = this.fullName.split(' ');

    return [nameParts[0][0], nameParts[1]?.[0]].filter(Boolean).join('');
  }
}
