import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';

import { ConfirmConfig } from '../../domain';

@Component({
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmComponent implements OnChanges {
  public title: string;
  public message: string;
  public details: string;
  public okLabel: string;
  public cancelLabel: string;
  public hideCancelButton: boolean;
  public modalBodyStyleClass: string;
  public message$: Observable<string>;

  @Input()
  public set confirmConfig(value: ConfirmConfig) {
    this.message$ = value.message$;
    this.title = value.title;
    this.message = value.message;
    this.details = value.details;
    this.okLabel = value.okLabel;
    this.cancelLabel = value.cancelLabel;
    this.hideCancelButton = value.hideCancelButton;
    this.modalBodyStyleClass = value.modalBodyStyleClass ?? '';
    this.changeDetectorRef.detectChanges();
  }

  @Output()
  public readonly confirmed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  public ngOnChanges(): void {
    this.changeDetectorRef.detectChanges();
  }

  public confirm(): void {
    this.confirmed.emit(true);
  }

  public decline(): void {
    this.confirmed.emit(false);
  }
}
