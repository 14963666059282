import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Message, MessageService } from 'primeng/api';

import { config } from '../domain';

@Injectable({
  providedIn: 'root',
})
export class EbfToastService {
  private readonly _newToast$: Subject<void> = new Subject<void>();
  private readonly timeout: number = 5000;
  public newToast$: Observable<void> = this._newToast$.asObservable();

  constructor(private readonly messageService: MessageService) {}

  public show(message: Message): void {
    this.messageService.clear(config.key);
    this.messageService.add({ ...message });
    this._newToast$.next();
  }

  public showSuccess(detail: string, summary: string = 'Success'): void {
    this.show({
      severity: 'success',
      summary,
      detail,
      life: this.timeout,
    });
  }

  public showWarning(detail: string, summary: string = 'Warning'): void {
    this.show({
      severity: 'warn',
      summary,
      detail,
      life: this.timeout,
    });
  }

  public showError(detail: string, summary: string = 'Error'): void {
    this.show({
      severity: 'error',
      summary,
      detail,
      life: this.timeout,
    });
  }

  public showInfo(detail: string, summary: string = 'Info'): void {
    this.show({
      severity: 'info',
      summary,
      detail,
      life: this.timeout,
    });
  }

  public close(): void {
    this.messageService.clear(config.key);
  }
}
