import { Observable } from 'rxjs';

export abstract class DataMapper<I, O> {
  public mapFrom(value: I, ...args: any[]): O | Observable<O> {
    throw new Error('Method mapFrom is not implemented ' + this.constructor.name);
  }

  public mapTo(value: O, ...args: any[]): I | Observable<I> {
    throw new Error('Method mapTo is not implemented in ' + this.constructor.name);
  }
}
