import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { DataMapper } from '@ebf-libs/sdk';

import { UserDto, UserForm } from '@ebf/features/users/domain';

@Injectable({
  providedIn: 'root',
})
export class UsersMapperService extends DataMapper<Partial<UserDto>, UserForm> {
  public mapTo(value: UserForm, uuid?: string): Partial<UserDto> {
    const { countryId, ...params } = value;
    const output: Partial<UserDto> = {
      ...params,
      country: { id: countryId },
    };

    return { ...output, uuid: uuid || uuidv4() };
  }
}
