import {
  Directive,
  Optional,
  ViewContainerRef,
  ComponentFactoryResolver,
  Input,
  Host,
  OnInit,
  OnDestroy,
  Self,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { merge, EMPTY, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormControl } from '@ngneat/reactive-forms';

import { ControlErrorContainerDirective } from './control-error-container.directive';
import { FormSubmitDirective } from './form-submit.directive';
import { EbfFormErrorsService } from '../services/ebf-form-errors.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControl], [formControlName]',
})
export class ControlErrorDirective implements OnInit, OnDestroy {
  @Input()
  public customErrors = {};
  @Input()
  public isValidationMessageVisible: boolean = true;

  private subscription: Subscription;
  private errorMessageElement: HTMLElement;

  private readonly container: ViewContainerRef;
  private readonly submit$: Observable<Event>;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    @Optional()
    private readonly controlErrorContainer: ControlErrorContainerDirective,
    @Optional()
    @Host()
    private readonly form: FormSubmitDirective,
    private readonly ivNgFormErrorsService: EbfFormErrorsService,
    @Self()
    private readonly ngControl: NgControl,
    private readonly elementRef: ElementRef,
    private readonly renderer2: Renderer2,
  ) {
    this.container = controlErrorContainer ? controlErrorContainer.viewContainerRef : viewContainerRef;
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
  }

  public ngOnInit(): void {
    this.subscription = merge(
      this.submit$,
      this.control.value$,
      this.control.dirty$,
      this.control.errors$,
      this.control.status$,
    )
      .pipe(debounceTime(100))
      .subscribe(() => {
        if (this.hasErrorsToDisplay) {
          if (this.isValidationMessageVisible) {
            this.setError(this.ivNgFormErrorsService.getErrorMessage(this.control, this.customErrors));
          }
          this.renderer2.addClass(this.elementRef.nativeElement, 'is-invalid');
        } else {
          this.setError(null);
          this.renderer2.removeClass(this.elementRef.nativeElement, 'is-invalid');
        }
      });
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.errorMessageElement) {
      this.destroyErrorMessageElem();
    }
  }

  private get hasErrorsToDisplay(): boolean {
    return (this.control.invalid && this.control.dirty) || (this.control.disabled && this.control.errors);
  }

  private get control(): FormControl {
    return this.ngControl.control as FormControl;
  }

  private setError(text: string): void {
    if (!text && !this.errorMessageElement) {
      return;
    }

    const errorMessageElem = this.errorMessageElement || this.createErrorMessageElem();
    const htmlText = this.renderer2.createText(text || '');

    errorMessageElem.textContent = '';
    this.renderer2.appendChild(errorMessageElem, htmlText);
  }

  private createErrorMessageElem(): HTMLElement {
    const span: HTMLElement = this.renderer2.createElement('small');
    const { nativeElement } = this.container.element;

    this.renderer2.addClass(span, 'd-block');
    this.renderer2.addClass(span, 'text-danger');
    this.renderer2.addClass(span, 'form-control-error');
    this.renderer2.insertBefore(nativeElement.parentNode, span, nativeElement.nextSibling);
    this.errorMessageElement = span;

    return span;
  }

  private destroyErrorMessageElem(): void {
    this.renderer2.removeChild(this.errorMessageElement.parentNode, this.errorMessageElement);
  }
}
