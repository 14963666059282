import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallMethodPipe } from './pipes/call-method.pipe';
import { CroppedTextPipe } from './pipes/cropped-text.pipe';

@NgModule({
  declarations: [CallMethodPipe, CroppedTextPipe],
  imports: [CommonModule],
  exports: [CallMethodPipe, CroppedTextPipe],
})
export class EbfUtilsModule {}
