<p-button *ngIf="!icon"
          [label]="label"
          [type]="type"
          [disabled]="disabled"
          [styleClass]="getComputedStyleClass()"
          [style.display]="'inline-block'"
          [style.width]="fullWidth ? '100%' : 'inherit'"
          [class.fullwidth]="fullWidth">
  <ng-content></ng-content>
</p-button>

<button *ngIf="icon"
        [type]="type"
        [class]="styleClass || 'btn-empty'">
  <img [src]="icon" alt="button" class="{{ iconClass }}">
</button>
