<!--<div *ngIf="title"-->
<!--     class="modal-header py-1 justify-content-center">-->
<!--  <h4 class="modal-title"-->
<!--      [attr.data-cy-modal-title]="title">-->
<!--    <i class="mdi mdi-information-outline"></i>-->
<!--    <span class="ml-2">{{ title }}</span>-->
<!--  </h4>-->
<!--</div>-->
<div class="modal-body font-16 {{ modalBodyStyleClass }} px-5 pt-5 pb-3"
     *ngIf="(message$ ? (message$ | async) : message) as text"
     [attr.data-cy-modal-message]="text">
  <h2 *ngIf="title">{{ title }}</h2>
  <div>{{ text }}</div>
  <div *ngIf="details" class="alert alert-danger mt-3">{{ details }}</div>
</div>
<footer class="d-flex justify-content-end pb-3 px-5">
  <button
    *ngIf="!hideCancelButton"
    class="btn btn-outline-dark m-2"
    [attr.data-cy-btn]="cancelLabel || ('shared.noLabel' | translate)"
    (click)="decline()">
    {{ cancelLabel || ('shared.noLabel' | translate) }}
  </button>
  <button
    class="btn btn-dark m-2"
    [attr.data-cy-btn]="okLabel || ('shared.yesLabel' | translate)"
    (click)="confirm()">
    {{ okLabel || ('shared.yesLabel' | translate) }}
  </button>
</footer>
