import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarModule } from 'primeng/progressbar';

import { RouterResolveProgressbarComponent } from './view/router-resolve-progressbar/router-resolve-progressbar.component';

@NgModule({
  declarations: [RouterResolveProgressbarComponent],
  exports: [RouterResolveProgressbarComponent, ProgressBarModule],
  imports: [CommonModule, ProgressBarModule],
})
export class EbfRouterResolveProgressbarModule {}
