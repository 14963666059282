const isFunction = (fn: any) => typeof fn === 'function';

export interface SubscriptionLike {
  unsubscribe(): void;
}

export class SubSink {
  private subscriptions: SubscriptionLike[] = [];

  set sink(subscription: SubscriptionLike) {
    this.subscriptions.push(subscription);
  }
  public unsubscribe(): void {
    this.subscriptions.forEach(sub => sub && isFunction(sub.unsubscribe) && sub.unsubscribe());
    this.subscriptions = [];
  }
}
