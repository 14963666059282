import { Directive, ElementRef, HostListener, Input, Optional, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[ebfNumbersOnly]',
})
export class NumbersOnlyDirective {
  private navigationKeys: string[] = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];
  @Input()
  public readonly decimal: boolean = false;
  @Input()
  public readonly maxDecimalLength: number = 4;
  @Input()
  public readonly decimalSeparator: string = '.';
  @Input()
  public readonly negativeNumbers: boolean;

  public inputElement: HTMLInputElement;

  constructor(
    @Optional()
    private readonly ngControl: NgControl,
    private readonly elementRef: ElementRef,
    private readonly renderer2: Renderer2,
  ) {
    this.inputElement = elementRef.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(keyboardEvent): void {
    if (
      this.navigationKeys.indexOf(keyboardEvent.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      (keyboardEvent.key === 'a' && keyboardEvent.ctrlKey === true) || // Allow: Ctrl+A
      (keyboardEvent.key === 'c' && keyboardEvent.ctrlKey === true) || // Allow: Ctrl+C
      (keyboardEvent.key === 'v' && keyboardEvent.ctrlKey === true) || // Allow: Ctrl+V
      (keyboardEvent.key === 'x' && keyboardEvent.ctrlKey === true) || // Allow: Ctrl+X
      (keyboardEvent.key === 'a' && keyboardEvent.metaKey === true) || // Allow: Cmd+A (Mac)
      (keyboardEvent.key === 'c' && keyboardEvent.metaKey === true) || // Allow: Cmd+C (Mac)
      (keyboardEvent.key === 'v' && keyboardEvent.metaKey === true) || // Allow: Cmd+V (Mac)
      (keyboardEvent.key === 'x' && keyboardEvent.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      return;
    } else if (keyboardEvent.key === this.decimalSeparator && !this.decimal) {
      keyboardEvent.preventDefault();
    } else if (
      keyboardEvent.key === this.decimalSeparator &&
      this.inputElement.value.includes(this.decimalSeparator)
    ) {
      keyboardEvent.preventDefault();
    } else if (keyboardEvent.key === ' ') {
      keyboardEvent.preventDefault();
    } else if (isNaN(Number(keyboardEvent.key))) {
      if (
        keyboardEvent.key === this.decimalSeparator ||
        (keyboardEvent.key === '-' && this.negativeNumbers)
      ) {
        return;
      } else {
        keyboardEvent.preventDefault();
      }
    }
  }

  @HostListener('input', ['$event'])
  public onInput(): void {
    if (this.decimalValueLength > this.maxDecimalLength) {
      this.setCorrectValue();
    }
  }

  @HostListener('paste', ['$event'])
  public onPaste(event) {
    const pastedInput: string = event.clipboardData.getData('text/plain');
    this.pasteData(pastedInput);
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  public onDrop(event) {
    const textData = event.dataTransfer.getData('text');
    this.inputElement.focus();
    this.pasteData(textData);
    event.preventDefault();
  }

  private get integerValue(): string {
    return this.splintedValue[0];
  }

  private get decimalValue(): string {
    return this.splintedValue[1] || '';
  }

  private get decimalValueLength(): number {
    return this.decimalValue.length;
  }

  private get splintedValue(): string[] {
    return this.decimal ? this.inputElement.value.split(this.decimalSeparator) : [this.inputElement.value];
  }

  private setCorrectValue(): void {
    const sanitizedValue = `${this.integerValue}.${this.decimalValue.slice(0, this.maxDecimalLength)}`;

    if (this.ngControl) {
      this.ngControl.control.setValue(sanitizedValue);
    } else {
      this.renderer2.setProperty(this.inputElement, 'value', sanitizedValue);
    }
  }

  private pasteData(pastedContent: string): void {
    const sanitizedContent = this.sanatizeInput(pastedContent);
    const pasted = document.execCommand('insertText', false, sanitizedContent);
    if (!pasted) {
      const { selectionStart: start, selectionEnd: end } = this.inputElement;
      this.inputElement.setRangeText(sanitizedContent, start, end, 'end');
    }
  }

  private sanatizeInput(input: string): string {
    let result: string;
    if (this.decimal && this.decimalValueLength < this.maxDecimalLength) {
      const regex = new RegExp(`[^0-9${this.decimalSeparator}]`, 'g');
      result = input.replace(regex, '');
    } else {
      result = input.replace(/[^0-9]/g, '');
    }

    const maxLength = this.inputElement.maxLength;
    if (maxLength > 0) {
      // the input element has maxLength limit
      const allowedLength = maxLength - this.inputElement.value.length;
      result = allowedLength > 0 ? result.substring(0, allowedLength) : '';
    }
    return result;
  }
}
