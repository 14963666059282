import { FormGroup, AbstractControl, FormArray, FormControl } from '@ngneat/reactive-forms';

export const validateFormFields = (abstractControl: AbstractControl): void => {
  if (abstractControl instanceof FormControl) {
    abstractControl.markAsDirty();
  } else if (abstractControl instanceof FormArray) {
    abstractControl.controls.forEach(item => validateFormFields(item));
  } else if (abstractControl instanceof FormGroup) {
    Object.keys(abstractControl.controls).forEach(key => validateFormFields(abstractControl.controls[key]));
  }
};

export const setCustomError = (abstractControl: AbstractControl, errorMessage: string): void => {
  abstractControl.setValue(abstractControl.value);
  abstractControl.setErrors({ custom: errorMessage });
  abstractControl.markAsDirty();
};

export const isFieldInvalid = (abstractControl: AbstractControl): boolean => {
  return abstractControl?.invalid && abstractControl.dirty;
};
