import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutLoginComponent } from '@ebf/core/layouts/view/layout-login/layout-login.component';
import { LayoutAppComponent } from '@ebf/core/layouts/view/layout-app/layout-app.component';
import { usersGuard } from '@ebf/features/users/guards/users.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LayoutLoginComponent,
  },
  {
    path: '',
    component: LayoutAppComponent,
    children: [
      {
        path: 'championships',
        loadChildren: () =>
          import('./features/championships/championships.module').then(m => m.ChampionshipsModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./features/users/users.module').then(m => m.UsersModule),
        canMatch: [usersGuard],
      },
      {
        path: 'faq',
        loadChildren: () => import('./features/faq/faq.module').then(m => m.FaqModule),
      },
      {
        path: '**',
        redirectTo: 'championships',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
