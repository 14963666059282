import { Component, OnDestroy } from '@angular/core';

import { config } from '@ebf-libs/sdk/platform/toast/lib/domain';
import { EbfToastService } from '@ebf-libs/sdk/platform/toast/lib/services/ebf-toast.service';
import { AutoUnsubscribable } from '@ebf-libs/sdk';

@Component({
  selector: 'ebf-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent extends AutoUnsubscribable implements OnDestroy {
  public readonly toastKey = config.key;
  public readonly iconClassMap = {
    info: 'dripicons-information',
    success: 'dripicons-checkmark',
    warn: 'dripicons-warning',
    error: 'dripicons-warning',
  };
  private interval: number;

  constructor(private readonly ebfToastService: EbfToastService) {
    super();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    clearInterval(this.interval);
  }

  public onToastClose(): void {
    this.ebfToastService.close();
  }
}
