import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EbfToastModule } from '../libs/sdk/platform/toast';
import { EbfRouterResolveProgressbarModule } from '../libs/sdk/platform/router-resolve-progressbar';
import { CoreModule } from './core/core.module';

@NgModule({
  declarations: [AppComponent],
  providers: [provideNgxMask()],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    NgxMaskDirective,
    NgxMaskPipe,
    EbfToastModule,
    EbfRouterResolveProgressbarModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
