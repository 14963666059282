import { Pipe, PipeTransform } from '@angular/core';

import { UserDto } from '@ebf/features/users/domain';
import { getUserFullName } from '@ebf/features/users/utils';

@Pipe({
  name: 'fullName',
  standalone: true,
})
export class FullNamePipe implements PipeTransform {
  public transform(user: Partial<UserDto>): string {
    return getUserFullName(user);
  }
}
