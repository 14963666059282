import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, take, tap, concatMap, skipWhile } from 'rxjs/operators';

import { LoginDto, UserState } from '@ebf/core/user/domain';
import { ApiService, HttpMethod } from '@ebf-libs/sdk/platform/api';
import { UsersApiService } from '@ebf/features/users/services/users-api.service';
import { UserDto, UserPermissions } from '@ebf/features/users/domain';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public readonly user$: BehaviorSubject<UserState> = new BehaviorSubject<UserState>({
    user: null,
    token: null,
  });
  private isRefreshing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly apiService: ApiService,
    private readonly usersApiService: UsersApiService,
    private readonly router: Router,
  ) {}

  public login(payload: LoginDto): Observable<any> {
    return this.refreshSession(payload);
  }

  public resetPassword(payload: Pick<UserDto, 'email'>): Observable<void> {
    return this.usersApiService.resetPassword(payload);
  }

  private setToken(token?: Pick<UserState, 'token' | 'refreshToken'>): void {
    const user = token ? this.getUserSnapshot() : null;
    const tokenData = token || { token: null, refreshToken: null };

    this.user$.next({
      user,
      ...tokenData,
    });

    if (token) {
      localStorage.setItem('token', token.token);
      localStorage.setItem('refreshToken', token.refreshToken);
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
    }
  }

  private setUser(user: UserDto): void {
    this.user$.next({
      ...this.user$.getValue(),
      user,
    });
  }

  public logout(force?: boolean): void {
    this.setToken(null);
    if (!location.pathname.startsWith('/login')) {
      this.router.navigateByUrl(this.getLoginUrl(), { state: { bypassGuards: force } });
    }
  }

  private getLoginUrl(): string {
    return '/login?backUrl=' + encodeURIComponent(location.pathname + location.search);
  }

  public refreshSession(payload?: LoginDto): Observable<boolean> {
    if (this.isRefreshing$.getValue()) {
      return this.isRefreshing$.pipe(
        skipWhile(Boolean),
        take(1),
        map(() => true),
      );
    }

    this.isRefreshing$.next(true);
    return this.apiService
      .request({
        method: HttpMethod.POST,
        url: '/token',
        body: payload || { refreshToken: this.getRefreshTokenSnapshot() },
      })
      .pipe(
        tap({
          next: response => {
            this.setToken(response);
            this.isRefreshing$.next(false);
          },
          error: () => this.logout(true),
          finalize: () => this.isRefreshing$.next(false),
        }),
        concatMap(() => this.refreshCurrentUser()),
        map(() => true),
      );
  }

  public refreshCurrentUser(): Observable<UserDto> {
    return this.usersApiService.getCurrentUser().pipe(tap(response => this.setUser(response)));
  }

  public checkPreviousSession(): Observable<any> {
    const tokenFromStorage = this.getTokenFromStorage();

    if (!tokenFromStorage.refreshToken) {
      this.logout();

      return of(null);
    }

    return this.refreshSession(tokenFromStorage);
  }

  private getTokenFromStorage(): Pick<UserState, 'token' | 'refreshToken'> {
    return { token: localStorage.getItem('token'), refreshToken: localStorage.getItem('refreshToken') };
  }

  public getUserSnapshot(): UserDto {
    return this.user$.getValue().user;
  }

  public getTokenSnapshot(): any {
    return this.user$.getValue().token;
  }

  public getRefreshTokenSnapshot(): any {
    return this.user$.getValue().refreshToken;
  }

  public isGuest(): boolean {
    return !this.getUserSnapshot();
  }

  public isAdmin(): boolean {
    return !!this.getUserSnapshot()?.admin;
  }

  public isJudge(championshipUuid: string, categoryUuid?: string): boolean {
    const permissions: UserPermissions = this.getUserSnapshot()?.permissions;

    return championshipUuid
      ? permissions?.judgeChampionships?.includes(championshipUuid)
      : permissions?.judgeCategories?.includes(categoryUuid);
  }

  public isResponsible(championshipUuid: string): boolean {
    return !!(
      championshipUuid &&
      this.getUserSnapshot()?.permissions?.responsibleChampionships?.includes(championshipUuid)
    );
  }

  public isEventManager(championshipUuid: string): boolean {
    return !!(
      championshipUuid &&
      this.getUserSnapshot()?.permissions?.eventManagerChampionships?.includes(championshipUuid)
    );
  }

  public isContentManager(championshipUuid: string): boolean {
    return !!(
      championshipUuid &&
      this.getUserSnapshot()?.permissions?.contentManagerChampionships?.includes(championshipUuid)
    );
  }

  public isTokenExpired(token: string): boolean {
    const decodedToken = this.getDecodeToken(token);
    return decodedToken ? new Date() > new Date(+`${decodedToken.exp}000`) : true;
  }

  private getDecodeToken(token: string) {
    try {
      return token
        ? JSON.parse(
            decodeURIComponent(
              atob(token.split('.')[1].replace('-', '+').replace('_', '/'))
                .split('')
                .map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
                .join(''),
            ),
          )
        : null;
    } catch (e) {
      console.log('Token parsing error');
      return null;
    }
  }
}
