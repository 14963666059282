import { HttpParams } from '@angular/common/http';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

export const getHttpParams = (params: Record<string, any>): HttpParams => {
  let httpParams: HttpParams = new HttpParams();
  if (isNil(params)) {
    return httpParams;
  }
  Object.keys(params).forEach(key => {
    if (isNil(params[key])) {
      return;
    }
    const paramValue = isString(params[key]) ? params[key].trim() : params[key];
    httpParams = httpParams.set(key, paramValue);
  });
  return httpParams;
};
export const getSanitizedBody = (body: Record<string, any> | string): Record<string, any> | string => {
  if (isNil(body)) {
    return null;
  }
  if (isString(body)) {
    return body.trim();
  }
  Object.keys(body).forEach(key => {
    if (isObject(body[key])) {
      body[key] = getSanitizedBody(body[key]);
    } else if (isString(body[key])) {
      body[key] = body[key].trim();
    } else if (isNil(body[key])) {
      body[key] = null;
    }
  });
  return body;
};
export const getUrlWithParams = (url: string, params: string) => {
  return url + (params?.length ? `&${params}` : '');
};
