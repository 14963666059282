import { Component, Input } from '@angular/core';

@Component({
  selector: 'ebf-root',
  template: `
    <ebf-toast></ebf-toast>
    <ebf-router-resolve-progressbar></ebf-router-resolve-progressbar>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent {
  @Input()
  public title: string;

  public user: any;
}
