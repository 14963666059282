import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationCancel, NavigationError, ResolveEnd, ResolveStart, Router } from '@angular/router';

import { AutoUnsubscribable } from '@ebf-libs/sdk/platform/domain';

@Component({
  selector: 'ebf-router-resolve-progressbar',
  templateUrl: './router-resolve-progressbar.component.html',
  styleUrls: ['./router-resolve-progressbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RouterResolveProgressbarComponent extends AutoUnsubscribable implements OnInit {
  public isProgressbarVisible: boolean;
  public styles = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: '6px',
    'z-index': 10000,
  };

  constructor(private readonly router: Router) {
    super();
  }

  public ngOnInit(): void {
    this.subSink.sink = this.router.events.subscribe(routerEvent => {
      if (routerEvent instanceof ResolveStart) {
        this.isProgressbarVisible = true;
      } else if (
        routerEvent instanceof ResolveEnd ||
        routerEvent instanceof NavigationCancel ||
        routerEvent instanceof NavigationError
      ) {
        this.isProgressbarVisible = false;
      }
    });
  }
}
