import { Pipe, PipeTransform } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Pipe({
  name: 'filteredMenu',
})
export class FilteredMenuPipe implements PipeTransform {
  transform(value: MenuItem[]): MenuItem[] {
    return value.filter(item => item.visible !== false);
  }
}
