import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { FormBuilder } from '@ngneat/reactive-forms';

import { ListResponse } from '@ebf-libs/sdk/platform/api';
import { UsersApiService } from '@ebf/features/users/services/users-api.service';
import { UsersMapperService } from '@ebf/features/users/services/users-mapper.service';
import { UserDto, UserForm, UsersQuery } from '@ebf/features/users/domain';
import { CountriesApiService } from '@ebf/features/countries/services/countries-api.service';
import { EbfToastService } from '@ebf-libs/sdk/platform/toast';
import { SelectItem, toSelectItemList } from '@ebf-libs/sdk/platform/forms';
import { UserService } from '@ebf/core/user/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class UsersFacadeService {
  constructor(
    private readonly usersApiService: UsersApiService,
    private readonly countriesApiService: CountriesApiService,
    private readonly usersMapperService: UsersMapperService,
    private readonly ebfToastService: EbfToastService,
    private readonly userService: UserService,
    public readonly formBuilder: FormBuilder,
  ) {}

  public getUsers(query: UsersQuery): Observable<ListResponse<UserDto>> {
    return this.usersApiService.getList(query);
  }

  public getCountries(): Observable<SelectItem[]> {
    return this.countriesApiService
      .getList()
      .pipe(shareReplay(1), toSelectItemList({ descriptionField: 'flagUrl' }));
  }

  public createUser(formValue: UserForm): Observable<UserDto> {
    return this.usersApiService
      .create(this.usersMapperService.mapTo(formValue))
      .pipe(tap(() => this.ebfToastService.showSuccess('User has been successfully created')));
  }

  public updateUser(formValue: UserForm, userId: string): Observable<void> {
    return this.usersApiService
      .update(this.usersMapperService.mapTo(formValue, userId))
      .pipe(tap(() => this.ebfToastService.showSuccess('User has been successfully updated')));
  }

  public isUserAdmin(): boolean {
    return this.userService.isAdmin();
  }
}
