import { Component, EventEmitter, Output } from '@angular/core';

import { CloseModalEvent } from '../../domain';
import { AbstractFormComponent } from './abstract-form.component';

@Component({
  template: '',
})
export abstract class AbstractModalFormComponent<T = any> extends AbstractFormComponent<T> {
  @Output()
  public readonly closeModal: EventEmitter<CloseModalEvent<any>> = new EventEmitter<CloseModalEvent<any>>();

  public onCloseModal(isSuccess?: boolean, payload?: any) {
    const closeModalEvent: CloseModalEvent = { isSuccess: Boolean(isSuccess), payload };

    this.isSuccess = isSuccess;

    if (this.hasUnsavedChanges()) {
      this.subSink.sink = this.ebfAbstractFormFacadeService
        .confirmFormLeaving()
        .subscribe((confirm: boolean) => (confirm ? this.closeModal.emit(closeModalEvent) : null));
    } else {
      this.closeModal.emit(closeModalEvent);
    }
  }
}
