import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

import { UsersFacadeService } from '@ebf/features/users/services/users-facade.service';
import { EbfToastService } from '@ebf-libs/sdk/platform/toast';

export const usersGuard: CanActivateFn = () => {
  const usersFacadeService: UsersFacadeService = inject(UsersFacadeService);
  const router: Router = inject(Router);
  const ebfToastService: EbfToastService = inject(EbfToastService);
  const canActivate: boolean = usersFacadeService.isUserAdmin();

  if (!canActivate) {
    ebfToastService.showError('You have no permissions to visit this source');
  }

  return canActivate || router.parseUrl('/championships');
};
