import { Injectable, OnDestroy } from '@angular/core';
import { SubSink } from '@ebf-libs/sdk/platform/utils';

@Injectable()
export abstract class AutoUnsubscribable implements OnDestroy {
  protected readonly subSink: SubSink = new SubSink();

  public ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
