import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MenuModule } from 'primeng/menu';
import { ReactiveFormsModule } from '@angular/forms';
import { EbfFormsModule } from '@ebf-libs/sdk/platform/forms';
import { EbfFormsValidationModule } from '@ebf-libs/sdk/platform/forms-validation';

import { LayoutLoginComponent } from '@ebf/core/layouts/view/layout-login/layout-login.component';
import { LayoutAppComponent } from '@ebf/core/layouts/view/layout-app/layout-app.component';
import { LayoutNotFoundComponent } from '@ebf/core/layouts/view/layout-not-found/layout-not-found.component';
import { FilteredMenuPipe } from '@ebf/core/layouts/view/filtered-menu.pipe';
import { EbfImageTextModule } from '@ebf-libs/sdk/platform/image-text/lib/ebf-image-text.module';
import { EbfAvatarModule } from '@ebf-libs/sdk/platform/avatar/lib/ebf-avatar.module';
import { FullNamePipe } from '@ebf/core/user/view/full-name.pipe';

@NgModule({
  declarations: [LayoutLoginComponent, LayoutAppComponent, LayoutNotFoundComponent, FilteredMenuPipe],
  exports: [LayoutLoginComponent, LayoutAppComponent, LayoutNotFoundComponent],
  imports: [
    CommonModule,
    RouterModule,
    EbfFormsModule,
    ReactiveFormsModule,
    EbfFormsValidationModule,
    MenuModule,
    EbfImageTextModule,
    EbfAvatarModule,
    FullNamePipe,
  ],
})
export class LayoutsModule {}
