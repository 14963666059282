export class LocaleLoadingException extends Error {
  public override name: string;

  constructor(failedLocaleName: string) {
    super(
      `Error during loading localization json file for language ${failedLocaleName}.
       Make sure file with name ${failedLocaleName.slice(0, 2)}.json is present in ./assets/lang folder.`,
    );
    this.name = 'LocaleLoadingException';
  }
}
