import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImageTextComponent } from '@ebf-libs/sdk/platform/image-text/lib/view/image-text/image-text.component';

@NgModule({
  declarations: [ImageTextComponent],
  imports: [CommonModule],
  exports: [ImageTextComponent],
})
export class EbfImageTextModule {}
