import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// This Interceptor is needed to fix an angular issue in HttpClient
// When request of type Blob, the error is also in Blob instead of object of the json data
// See more: https://github.com/angular/angular/issues/19888
@Injectable()
export class BlobErrorTransformerInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => {
        if (
          err instanceof HttpErrorResponse &&
          err.error instanceof Blob &&
          err.error.type === 'application/json'
        ) {
          return new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: Event) => {
              try {
                reject(
                  new HttpErrorResponse({
                    error: JSON.parse((e.target as any).result),
                    headers: err.headers,
                    status: err.status,
                    statusText: err.statusText,
                    url: err.url,
                  }),
                );
              } catch (e) {
                reject(err);
              }
            };
            reader.onerror = () => {
              reject(err);
            };
            reader.readAsText(err.error);
          });
        }
        return throwError(err);
      }),
    );
  }
}
