import { Injectable, isDevMode, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { SubSink } from '@ebf-libs/sdk/platform/utils';

import { LocaleConfig, LocaleRegistry } from '../domain';
import { LocaleLoadingException, MissedLocaleKeyException } from '../exceptions';
import { getFormattedString } from '../utils/string.util';

@Injectable({
  providedIn: 'root',
})
export class EbfLocalizationService implements OnDestroy {
  private _availableLocales: LocaleConfig[] = [];
  private readonly _locale$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private readonly _localeRegistry: LocaleRegistry = {};
  private readonly _subSink: SubSink = new SubSink();

  public localeChanged$: Observable<string> = this._locale$.asObservable().pipe(skipWhile(locale => !locale));

  constructor(private readonly httpClient: HttpClient) {}

  public ngOnDestroy(): void {
    this._subSink.unsubscribe();
  }

  public translate(key: string, params?: { [key: string]: any }, defaultValue?: string): string {
    const currentLocaleKey = this._locale$.getValue();
    if (!currentLocaleKey) {
      return defaultValue ?? '';
    }

    const localeMap = this._localeRegistry[currentLocaleKey];
    if (!localeMap[key] && defaultValue === undefined) {
      const missedLocaleKeyException = new MissedLocaleKeyException(key, currentLocaleKey);
      if (isDevMode()) {
        throw missedLocaleKeyException;
      } else {
        console.error(missedLocaleKeyException.message);
        return key;
      }
    } else if (!localeMap[key] && defaultValue !== undefined) {
      return defaultValue;
    }

    return params && typeof params === 'object' ? getFormattedString(localeMap[key], params) : localeMap[key];
  }

  public setLocale(localeName: string): void {
    if (Object.keys(this._localeRegistry).indexOf(localeName) === -1) {
      this._subSink.sink = this.httpClient
        .get<{ [key: string]: string }>(`./assets/lang/${localeName}.json`)
        .subscribe(
          (data: Record<string, string>) => {
            this._localeRegistry[localeName] = data;
            this._locale$.next(localeName);
          },
          () => {
            throw new LocaleLoadingException(localeName);
          },
        );
    } else {
      this._locale$.next(localeName);
    }
  }

  public getCurrentLocale(): string {
    return this._locale$.getValue();
  }

  public set availableLocales(availableLocales: LocaleConfig[]) {
    this._availableLocales = availableLocales;
  }

  public get availableLocales(): LocaleConfig[] {
    return this._availableLocales;
  }

  public get currentLocaleConfig(): LocaleConfig {
    const current = this._locale$.getValue();

    return this.availableLocales.find((localeItem: LocaleConfig): boolean => localeItem.key === current);
  }
}
