import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { EbfLocalizationModule } from '@ebf-libs/sdk/platform/localization';

import { ConfirmComponent } from './view/confirm/confirm.component';

@NgModule({
  declarations: [ConfirmComponent],
  imports: [CommonModule, ModalModule, EbfLocalizationModule],
})
export class EbfConfirmModule {}
