import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { EventDto } from '@ebf/shared/events/domain';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventsApiService {
  private _eventsSubject: WebSocketSubject<EventDto>;
  public events: Observable<EventDto>;

  public connect(): void {
    this.disconnect();
    this._eventsSubject = webSocket<EventDto>(environment.api.ws);
    this.events = this._eventsSubject.pipe(shareReplay(1));
  }

  public disconnect(): void {
    this._eventsSubject?.complete();
    this._eventsSubject = null;
    this.events = null;
  }

  public getWithFilter(filterFn: (event: EventDto) => boolean): Observable<EventDto> {
    return this.events ? this.events.pipe(filter(filterFn)) : EMPTY;
  }
}
