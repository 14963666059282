<div class="layout-wrapper">
  <div class="topbar py-2 py-lg-0">
    <button class="hamburger hamburger--3dxy-r d-block d-lg-none"
            [class.is-active]="isMobileMenuOpen"
            type="button"
            (click)="onToggleMobile()">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
    <a routerLink="/championships">
      <img src="./assets/images/logo-topbar.svg"
           alt="Company Logo"
           class="logo pr-4 d-none d-lg-block">
    </a>
    <div>
      <ng-container *ngIf="user">
        <p-menu #userMenu [model]="userMenuItems" [popup]="true"></p-menu>
        <ebf-button theme="transparent" styleClass="p-0" (click)="userMenu.toggle($event)">
          <span class="d-flex align-items-center text-transform-initial">
            <ebf-image-text [reverse]="true">
              <ebf-avatar [fullName]="user | fullName"
                          size="large"
                          class="image ml-2">
              </ebf-avatar>
              <span class="color-black font-weight-600">Hello, {{ user | fullName }}!</span>
              <span *ngIf="role$ | async as role" class="role d-block color-light-gray font-14 text-right">{{ role }}</span>
            </ebf-image-text>
            <img src="./assets/images/icons/arrow-down-gray.svg" alt="Arrow down" class="mx-3">
          </span>
        </ebf-button>
      </ng-container>
      <ebf-button *ngIf="!user"
                  label="Login"
                  routerLink="/login">
      </ebf-button>
    </div>
  </div>

  <div class="sidebar"
       [class.is-active]="isMobileMenuOpen">
    <ul class="list-unstyled">
      <li *ngFor="let item of menuItems | filteredMenu"
          class="d-flex align-items-center">
        <a [routerLink]="item.routerLink"
           routerLinkActive="selected">
          <img [src]="'./assets/images/icons' + item.routerLink + '.svg'"
               [alt]="item.label">
          <span class="px-3">{{ item.label }}</span>
        </a>
      </li>
    </ul>
  </div>

  <div class="content">
    <router-outlet></router-outlet>
    <div class="footer">
      <div>
        <a href="mailto:info@eyebrowfestival.com" class="email">info@eyebrowfestival.com</a>
        <div class="d-flex">
          <span class="mr-2">Office: <a href="tel:+31181320708">+31 181320708</a></span>
          <span>Mobile: <a href="tel:+31625108313">+31625108313</a></span>
        </div>
      </div>

      <div class="d-flex">
        <div class="mr-5">
          <img src="./assets/images/icons/ball.svg" alt="Website">
          <a href="http://eyebrowfestival.com" class="ml-2 font-14" target="_blank">Festival website</a>
        </div>
        <div>
          <a href="https://www.facebook.com/Eyebrowfestival/" target="_blank" class="mr-2">
            <img src="./assets/images/icons/facebook.svg" alt="facebook">
          </a>
          <a href="https://www.instagram.com/worldwide_eyebrowfestival/" target="_blank" class="mr-2">
            <img src="./assets/images/icons/instagram.svg" alt="instagram">
          </a>
          <a href="https://wa.me/31625108313" target="_blank" class="mr-2">
            <img src="./assets/images/icons/whatsapp.svg" alt="whatsapp">
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
