import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { ConfirmComponent } from '../view/confirm/confirm.component';
import { ConfirmConfig } from '../domain';

@Injectable({
  providedIn: 'root',
})
export class EbfConfirmService {
  constructor(private readonly modalService: BsModalService) {}

  public confirm(confirmConfig: ConfirmConfig): Observable<boolean> {
    const modalRef: BsModalRef = this.modalService.show(ConfirmComponent, {
      class: 'modal-md',
      ignoreBackdropClick: true,
      keyboard: false,
      animated: true,
    });
    modalRef.content.confirmConfig = confirmConfig;

    return modalRef.content.confirmed.pipe(
      take(1),
      tap(() => modalRef.hide()),
    );
  }
}
