<div class="input-wrapper">
  <label *ngIf="label && type !== 'switch'"
         for="{{ formControlName || id }}"
         [attr.required]="isRequired">
    {{ label }}
  </label>

  <ng-container [ngSwitch]="type">
    <textarea
      *ngSwitchCase="'textarea'"
      [id]="formControlName || id"
      [name]="formControlName"
      [placeholder]="placeholder"
      rows="5"
      cols="30"
      class="form-control"
      [ngModel]="value"
      (ngModelChange)="onInputChange($event)"
    >
    </textarea>

    <p-password  *ngSwitchCase="'password'"
                 [id]="formControlName || id"
                 [placeholder]="placeholder"
                 [toggleMask]="true"
                 [feedback]="false"
                 [ngModel]="value"
                 (ngModelChange)="onInputChange($event)">
    </p-password>

    <div *ngSwitchCase="'switch'" class="d-flex align-items-center">
      <span>
        <p-inputSwitch [inputId]="formControlName || id"
                       [disabled]="disabled"
                       [ngModel]="value"
                       (ngModelChange)="onInputChange($event)">
        </p-inputSwitch>
      </span>

      <label [for]="formControlName || id" class="ml-3 mb-1">{{ label }}</label>
    </div>



    <div *ngSwitchCase="'radio'"
         class="d-flex">
      <div *ngFor="let option of options" class="d-flex align-items-center mr-3">
        <p-radioButton [name]="formControlName || id"
                       [value]="option[optionValue]"
                       [disabled]="option.disabled"
                       [ngModel]="value"
                       [inputId]="id || formControlName"
                       (ngModelChange)="onInputChange($event)">
        </p-radioButton>
        <label [attr.for]="id || formControlName" class="m-0 ml-2">{{ option[optionLabel] }}</label>
      </div>
    </div>

    <div *ngSwitchCase="'date'">
      <p-calendar
        [placeholder]="placeholder"
        [inputId]="formControlName || id"
        [name]="formControlName || id"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [ngModel]="value"
        dateFormat="dd/mm/yy"
        (ngModelChange)="onInputChange($event)">
      </p-calendar>
    </div>

    <input
      *ngSwitchCase="'time'"
      [id]="formControlName || id"
      [type]="type"
      [name]="formControlName || id"
      [placeholder]="placeholder"
      class="form-control"
      [ngModel]="value"
      (ngModelChange)="onInputChange($event)"
    />

    <input
      *ngSwitchCase="'number'"
      [id]="formControlName || id"
      type="text"
      [name]="formControlName || id"
      [placeholder]="placeholder"
      ebfNumbersOnly
      class="form-control"
      [ngModel]="value"
      (ngModelChange)="onInputChange($event)"
    />



    <input
      *ngSwitchDefault
      [id]="formControlName || id"
      [type]="type"
      [name]="formControlName || id"
      [placeholder]="placeholder"
      class="form-control"
      [ngModel]="value"
      (ngModelChange)="onInputChange($event)"
    />
  </ng-container>
</div>
