import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup } from '@ngneat/reactive-forms';
import { EbfLocalizationService } from '@ebf-libs/sdk/platform/localization';
import { EbfToastService } from '@ebf-libs/sdk/platform/toast';
import { SubSink } from '@ebf-libs/sdk/platform/utils';
import { ApiError, ApiErrorItem, HttpStatus } from '@ebf-libs/sdk/platform/api';

import { setCustomError } from '../utils/form.util';

@Injectable({
  providedIn: 'root',
})
export class EbfFormErrorsService implements OnDestroy {
  private readonly _subSink: SubSink = new SubSink();
  private readonly httpStatusesForShowToast = [HttpStatus.BAD_REQUEST, HttpStatus.REQUEST_TIMEOUT];

  constructor(
    private readonly ebfLocalizationService: EbfLocalizationService,
    private readonly ebfToastService: EbfToastService,
  ) {}

  public ngOnDestroy(): void {
    this._subSink.unsubscribe();
  }

  public getErrorMessage(
    { errors }: AbstractControl,
    customErrorMessagesMap?: Record<string, string>,
  ): string {
    if (!errors || typeof errors !== 'object') {
      return '';
    }
    const errorKey = Object.keys(errors)[0];

    if (customErrorMessagesMap?.hasOwnProperty(errorKey)) {
      return customErrorMessagesMap[errorKey];
    }

    if (errorKey === 'custom') {
      return errors[errorKey] || this.ebfLocalizationService.translate('validation.error.default');
    }

    return this.ebfLocalizationService.translate(
      `validation.error.${errorKey}`,
      errors[errorKey],
      'validation.error.default',
    );
  }

  private getControlByKey(
    key: string,
    formGroup: FormGroup,
    keyMapper?: Record<string, string>,
  ): AbstractControl {
    if (keyMapper?.hasOwnProperty(key)) {
      return formGroup.get(keyMapper[key]);
    } else if (key.slice(key.length - 3) === '.id') {
      return formGroup.get(key.slice(0, key.length - 3));
    } else if (key.slice(key.length - 2) === 'Id') {
      return formGroup.get(key.slice(0, key.length - 2)) || formGroup.get(key);
    } else {
      return formGroup.get(key);
    }
  }

  public setApiErrorsToForm(
    apiError: ApiError,
    formGroup: FormGroup,
    keyMapper?: Record<string, string>,
    errorCodeMapper?: Record<string, Record<string, string>>,
  ): void {
    const errorsWithNoTarget: ApiErrorItem[] = [];

    if (!apiError.errors?.length) {
      return;
    }

    apiError.errors.forEach(error => {
      if (!error.target) {
        errorsWithNoTarget.push(error);

        return;
      }

      const invalidFormControl = this.getControlByKey(error.target, formGroup, keyMapper);

      if (!invalidFormControl) {
        errorsWithNoTarget.push(error);

        return;
      }

      let message;

      if (error.code) {
        const messageKey = errorCodeMapper?.[error.target]?.[error.code]
          ? errorCodeMapper[error.target][error.code]
          : `validation.error.${error.code.toLowerCase()}`;
        message = this.ebfLocalizationService.translate(
          messageKey,
          null,
          `${error.message} (Error code: ${error.code})`,
        );
      } else {
        message = error.message;
      }

      setCustomError(invalidFormControl, message);
    });

    if (errorsWithNoTarget.length && this.httpStatusesForShowToast.includes(apiError.status)) {
      this.showApiErrorInToast(
        errorsWithNoTarget.map(error => ({
          ...error,
          message: [error.target, error.message].filter(Boolean).join(' '),
        })),
      );
    }
  }

  public showApiErrorInToast(errorsWithNoTarget: ApiErrorItem[]): void {
    const firstError = errorsWithNoTarget[0];
    const message = firstError.message
      ? firstError.message
      : this.ebfLocalizationService.translate(`httpError.category.${firstError.category}`);

    this.ebfToastService.showError(message, this.ebfLocalizationService.translate('shared.errorHeader'));
  }
}
