import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService, HttpMethod, ListResponse } from '@ebf-libs/sdk/platform/api';
import { CountryDto } from '@ebf/features/countries/domain';

@Injectable({
  providedIn: 'root',
})
export class CountriesApiService {
  public readonly baseUrl: string = '/countries';
  constructor(private readonly apiService: ApiService) {}

  public getList(): Observable<ListResponse<CountryDto>> {
    return this.apiService.request({
      method: HttpMethod.GET,
      url: this.baseUrl,
    });
  }
}
