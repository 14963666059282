import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ControlErrorContainerDirective } from './directives/control-error-container.directive';
import { FormSubmitDirective } from './directives/form-submit.directive';
import { ControlErrorDirective } from './directives/control-error.directive';

@NgModule({
  declarations: [ControlErrorDirective, ControlErrorContainerDirective, FormSubmitDirective],
  imports: [CommonModule],
  exports: [ControlErrorDirective, ControlErrorContainerDirective, FormSubmitDirective],
})
export class EbfFormsValidationModule {}
