import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import { EbfLocalizationModule } from '@ebf-libs/sdk/platform/localization';
import { ToastComponent } from '@ebf-libs/sdk/platform/toast';

@NgModule({
  declarations: [ToastComponent],
  providers: [MessageService],
  imports: [CommonModule, ToastModule, EbfLocalizationModule],
  exports: [ToastModule, ToastComponent],
})
export class EbfToastModule {}
