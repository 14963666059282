import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ebf-image-text',
  templateUrl: './image-text.component.html',
  styleUrls: ['./image-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageTextComponent {
  @Input()
  public imageSrc: string;
  @Input()
  public imageAlt: string = 'Image';
  @Input()
  public verticalAlign: 'center' | 'start' = 'center';
  @Input()
  public reverse: boolean;
}
