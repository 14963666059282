import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService, HttpMethod, ListResponse } from '@ebf-libs/sdk/platform/api';
import { UserDto, UsersQuery } from '@ebf/features/users/domain';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  public readonly baseUrl: string = '/users';
  constructor(private readonly apiService: ApiService) {}

  public getList(query: UsersQuery): Observable<ListResponse<UserDto>> {
    return this.apiService.request({
      method: HttpMethod.GET,
      url: this.baseUrl,
      params: query,
    });
  }

  public create(payload: Partial<UserDto>): Observable<UserDto> {
    return this.apiService.request({
      method: HttpMethod.POST,
      url: this.baseUrl,
      body: payload,
    });
  }

  public update(payload: Partial<UserDto>): Observable<void> {
    return this.apiService.request({
      method: HttpMethod.PUT,
      url: `${this.baseUrl}/${payload.uuid}`,
      body: payload,
    });
  }

  public resetPassword(payload: Pick<UserDto, 'email'>): Observable<void> {
    return this.apiService.request({
      method: HttpMethod.POST,
      url: `${this.baseUrl}/reset-password`,
      body: payload,
    });
  }

  public getCurrentUser(): Observable<UserDto> {
    return this.apiService.request({
      method: HttpMethod.GET,
      url: `${this.baseUrl}/me`,
    });
  }
}
