import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DataMapper } from '@ebf-libs/sdk/platform/domain';

import { ApiError } from '../domain';

@Injectable({
  providedIn: 'root',
})
export class ApiErrorMapper extends DataMapper<HttpErrorResponse, ApiError> {
  public override mapFrom(value: Partial<HttpErrorResponse>): ApiError {
    const { errors, ...attributes } = value.error || {};
    return {
      status: value.status,
      headers: value.headers,
      message: value.message,
      url: value.url,
      errors: errors || [],
      attributes,
    };
  }
}
