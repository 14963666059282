import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  ApiPrefixInterceptor,
  ApiTokenInterceptor,
  BlobErrorTransformerInterceptor,
  ErrorHandlerInterceptor,
} from '@ebf-libs/sdk/platform/http';

@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BlobErrorTransformerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiTokenInterceptor, multi: true },
  ],
  imports: [HttpClientModule],
  exports: [HttpClientModule],
})
export class HttpModule {}
