import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { Observable } from 'rxjs';
import { ConfirmConfig, EbfConfirmService } from '@ebf-libs/sdk/platform/confirm';
import { EbfToastService } from '@ebf-libs/sdk/platform/toast';
import { EbfLocalizationService } from '@ebf-libs/sdk/platform/localization';
import { EbfFormErrorsService } from '@ebf-libs/sdk/platform/forms-validation';
import { ApiError, ApiErrorItem } from '@ebf-libs/sdk/platform/api';

@Injectable({
  providedIn: 'root',
})
export class EbfAbstractFormFacadeService {
  public readonly localeChanged$: Observable<any> = this.ebfLocalizationService.localeChanged$;

  constructor(
    public readonly ebfToastService: EbfToastService,
    public readonly ebfConfirmService: EbfConfirmService,
    public readonly ebfLocalizationService: EbfLocalizationService,
    public readonly ebfFormErrorsService: EbfFormErrorsService,
    public readonly formBuilder: FormBuilder,
  ) {}

  public confirmFormLeaving(): Observable<boolean> {
    return this.ebfConfirmService.confirm({
      title: this.ebfLocalizationService.translate('shared.warningHeader'),
      message: this.ebfLocalizationService.translate('shared.riskyButtonWarningMessage'),
      okLabel: this.ebfLocalizationService.translate('shared.yesLabel'),
      cancelLabel: this.ebfLocalizationService.translate('shared.noLabel'),
    });
  }

  public confirm(config: ConfirmConfig): Observable<boolean> {
    return this.ebfConfirmService.confirm(config);
  }

  public showSuccessToast(title: string, message: string): void {
    this.ebfToastService.showSuccess(title, message);
  }

  public showInfoToast(title: string, message: string): void {
    this.ebfToastService.showInfo(title, message);
  }

  public showWarningToast(title: string, message: string): void {
    this.ebfToastService.showWarning(title, message);
  }

  public showErrorToast(title: string, message: string): void {
    this.ebfToastService.showError(title, message);
  }

  public translate(message: string, params?: { [key: string]: any }, defaultValue?: string): string {
    return this.ebfLocalizationService.translate(message, params, defaultValue);
  }

  public setApiErrorsToForm(apiError: ApiError, formGroup: FormGroup): void {
    this.ebfFormErrorsService.setApiErrorsToForm(apiError, formGroup);
  }

  public showApiErrorsInToast(apiErrorItems: ApiErrorItem[]): void {
    this.ebfFormErrorsService.showApiErrorInToast(apiErrorItems);
  }
}
