import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ebf-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input()
  public label: string;
  @Input()
  public type: 'button' | 'submit' = 'button';
  @Input()
  public theme: 'black' | 'outline-black' | 'orange' | 'transparent' | 'white' = 'black';
  @Input()
  public styleClass: string;
  @Input()
  public fullWidth: boolean;
  @Input()
  public routerLink: string;
  @Input()
  public disabled: boolean;
  @Input()
  public icon: string;
  @Input()
  public iconClass: string;

  public getComputedStyleClass(): string {
    return [this.styleClass, this.fullWidth ? 'fullwidth' : '', `bg-${this.theme}`].filter(Boolean).join(' ');
  }
}
