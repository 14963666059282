import { map } from 'rxjs/operators';
import isFunction from 'lodash/isFunction';

import { SelectItem } from '../domain';

export interface ToSelectItemListParams<I, O> {
  labelField?: string;
  descriptionField?: string;
  valueField?: string;
  labelFieldFn?: (item: I) => string;
  descriptionFieldFn?: (item: I) => string;
  valueFieldFn?: (item: I) => O;
  readonlyFn?: (item: I) => boolean;
  metaFn?: (item: I) => any;
}

export const createSelectItem = <I extends Record<string, any>, O>(
  item: I,
  params?: ToSelectItemListParams<I, O>,
): SelectItem => {
  const labelField: string = params?.labelField || 'name';
  const valueField: string = params?.valueField;
  const descriptionField: string = params?.descriptionField;

  if (!item) {
    return null;
  }

  return {
    label: isFunction(params?.labelFieldFn) ? params.labelFieldFn(item) : item[labelField],
    description: isFunction(params?.descriptionFieldFn)
      ? params.descriptionFieldFn(item)
      : descriptionField
      ? item[descriptionField]
      : null,
    value: isFunction(params?.valueFieldFn)
      ? params.valueFieldFn(item)
      : item[valueField] ?? item.id ?? item.uuid,
    readonly: isFunction(params?.readonlyFn) ? params.readonlyFn(item) : false,
    meta: isFunction(params?.metaFn) ? params.metaFn(item) : null,
  };
};

export const toSelectItemList = <I, O = any>(params?: ToSelectItemListParams<I, O>) => {
  return map((data: I[] | { items: I[] }): SelectItem<O>[] => {
    const items: I[] = Array.isArray(data) ? data : Array.isArray(data?.items) ? data.items : [];

    return items
      .map((item: I) => createSelectItem<I, O>(item, params))
      .sort((a: SelectItem, b: SelectItem): number =>
        a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
      );
  });
};
