import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiErrorItem, HttpStatus } from '@ebf-libs/sdk/platform/api';
import { EbfToastService } from '@ebf-libs/sdk/platform/toast';
import { EbfLocalizationService } from '@ebf-libs/sdk/platform/localization';
import { UserService } from '@ebf/core/user/services/user.service';
import { AutoUnsubscribable } from '@ebf-libs/sdk';

@Injectable()
export class ErrorHandlerInterceptor extends AutoUnsubscribable implements HttpInterceptor {
  constructor(
    private readonly ebfToastService: EbfToastService,
    private readonly userService: UserService,
    private readonly ebfLocalizationService: EbfLocalizationService,
    private readonly router: Router,
  ) {
    super();
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    const shouldHandleBadRequest = req.headers.has('meta-shouldHandleBadRequest')
      ? req.headers.get('meta-shouldHandleBadRequest') === 'true'
      : false;
    const shouldHandleForbiddenRequest = req.headers.has('meta-shouldHandleForbiddenRequest')
      ? req.headers.get('meta-shouldHandleForbiddenRequest') === 'true'
      : true;

    return next
      .handle(req)
      .pipe(
        catchError(err => this.httpErrorHandler(err, shouldHandleBadRequest, shouldHandleForbiddenRequest)),
      );
  }

  private httpErrorHandler(
    httpErrorResponse: HttpErrorResponse,
    shouldHandleBadRequest: boolean,
    shouldHandleNotFoundRequest: boolean,
  ): Observable<HttpEvent<HttpErrorResponse>> {
    switch (httpErrorResponse.status) {
      case HttpStatus.BAD_REQUEST:
        return shouldHandleBadRequest
          ? this.badRequestHandler(httpErrorResponse)
          : throwError(httpErrorResponse);
      case HttpStatus.NOT_FOUND:
        return shouldHandleNotFoundRequest
          ? this.notFoundErrorHandler(httpErrorResponse)
          : throwError(httpErrorResponse);
      case HttpStatus.INTERNAL_ERROR:
        return this.serverErrorHandler(httpErrorResponse);
      case HttpStatus.BAD_GATEWAY:
      case HttpStatus.GATEWAY_TIMEOUT:
      case HttpStatus.SERVICE_UNAVAILABLE:
        return this.gatewayErrorHandler(httpErrorResponse);
      case HttpStatus.UNAUTHORIZED:
        return this.unauthorizedErrorHandler(httpErrorResponse);
      case HttpStatus.OFFLINE:
        return this.offlineErrorHandler(httpErrorResponse);
      default:
        return this.serverErrorHandler(httpErrorResponse);
    }
  }

  private unauthorizedErrorHandler(
    httpErrorResponse: HttpErrorResponse,
  ): Observable<HttpEvent<HttpErrorResponse>> {
    const message = httpErrorResponse.error?.errors?.[0]?.message;

    this.ebfToastService.showError(
      message ||
        `${this.ebfLocalizationService.translate(
          'httpError.category.UNAUTHORIZED_ERROR',
        )}${this.getTraceIdMessage(httpErrorResponse)}`,
      this.ebfLocalizationService.translate('shared.errorHeader'),
    );
    this.userService.logout(true);

    return throwError(httpErrorResponse);
  }

  private gatewayErrorHandler(
    httpErrorResponse: HttpErrorResponse,
  ): Observable<HttpEvent<HttpErrorResponse>> {
    this.ebfToastService.showError(
      this.ebfLocalizationService.translate('shared.errorHeader'),
      `${this.ebfLocalizationService.translate('httpError.category.GATEWAY_TIMEOUT')}${this.getTraceIdMessage(
        httpErrorResponse,
      )}`,
    );

    return throwError(httpErrorResponse);
  }

  private serverErrorHandler(httpErrorResponse: HttpErrorResponse): Observable<HttpEvent<HttpErrorResponse>> {
    let errors = '';

    httpErrorResponse.error?.errors?.forEach((httpError: ApiErrorItem) => {
      errors += this.ebfLocalizationService.translate(
        `httpError.category.${httpError.category}`,
        null,
        httpError.message,
      );
    });
    this.ebfToastService.showError(
      errors
        ? errors + this.getTraceIdMessage(httpErrorResponse)
        : `${this.ebfLocalizationService.translate(
            'httpError.category.UNKNOWN_ERROR',
          )}${this.getTraceIdMessage(httpErrorResponse)}`,
      this.ebfLocalizationService.translate('shared.errorHeader'),
    );

    return throwError(httpErrorResponse);
  }

  private offlineErrorHandler(
    httpErrorResponse: HttpErrorResponse,
  ): Observable<HttpEvent<HttpErrorResponse>> {
    this.ebfToastService.showError(
      this.ebfLocalizationService.translate('shared.errorHeader'),
      `${this.ebfLocalizationService.translate('httpError.category.UNKNOWN')}${this.getTraceIdMessage(
        httpErrorResponse,
      )}`,
    );

    return throwError(httpErrorResponse);
  }

  private notFoundErrorHandler(
    httpErrorResponse: HttpErrorResponse,
  ): Observable<HttpEvent<HttpErrorResponse>> {
    const defaultMessage = this.ebfLocalizationService.translate(`httpError.category.NOT_FOUND`);
    const message = httpErrorResponse.error.errors
      ? httpErrorResponse.error.errors[0].message || defaultMessage
      : defaultMessage;

    this.ebfToastService.showError(
      `${message}${this.getTraceIdMessage(httpErrorResponse)}`,
      this.ebfLocalizationService.translate('shared.errorHeader'),
    );
    this.router.navigateByUrl('/championships');

    return throwError(httpErrorResponse);
  }

  private badRequestHandler(httpErrorResponse: HttpErrorResponse): Observable<HttpEvent<HttpErrorResponse>> {
    const error: ApiErrorItem = (
      typeof httpErrorResponse.error === 'string'
        ? JSON.parse(httpErrorResponse.error)
        : httpErrorResponse.error
    )?.errors?.[0];

    this.ebfToastService.showError(
      this.ebfLocalizationService.translate('shared.errorHeader'),
      error?.message
        ? error?.message + this.getTraceIdMessage(httpErrorResponse)
        : `${this.ebfLocalizationService.translate(
            'httpError.category.INVALID_REQUEST',
          )}${this.getTraceIdMessage(httpErrorResponse)}`,
    );

    return throwError(httpErrorResponse);
  }

  private getTraceIdMessage(httpErrorResponse: HttpErrorResponse): string {
    const traceId = httpErrorResponse.headers.get('trace-id');

    return `${traceId ? `\n Request id: ${traceId}` : ''}`;
  }
}
