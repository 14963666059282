import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: '[ebfPending]',
})
export class PendingDirective implements OnChanges, AfterViewInit {
  @Input('ebfPending')
  public isPending: boolean;
  @Input('ebfPendingTheme')
  public theme: 'dark' | 'white' = 'white';

  private spinnerElement: HTMLDivElement;
  private hasCustomPositionStyle: boolean;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer2: Renderer2,
  ) {}

  public ngOnChanges(): void {
    setTimeout(() => this.updateElementStatus());
  }

  public ngAfterViewInit(): void {
    this.hasCustomPositionStyle = getComputedStyle(this.elementRef.nativeElement).position !== 'static';
  }

  private updateElementStatus(): void {
    if (this.isPending) {
      this.renderer2.setAttribute(this.elementRef.nativeElement, 'disabled', 'true');
      this.addSpinner();
      this.addRelativePositionToHost();
    } else {
      this.renderer2.removeAttribute(this.elementRef.nativeElement, 'disabled');
      this.removeSpinner();
      this.removeRelativePositionFromHost();
    }
  }

  private addSpinner(): void {
    this.spinnerElement = this.spinnerElement || this.createSpinnerElement();

    this.renderer2.insertBefore(
      this.elementRef.nativeElement,
      this.spinnerElement,
      this.elementRef.nativeElement.firstChild,
    );
  }

  private removeSpinner(): void {
    if (!this.spinnerElement) {
      return;
    }

    this.renderer2.removeChild(this.elementRef.nativeElement, this.spinnerElement);
  }

  private addRelativePositionToHost(): void {
    if (!this.hasCustomPositionStyle) {
      this.renderer2.addClass(this.elementRef.nativeElement, 'position-relative');
    }
  }

  private removeRelativePositionFromHost(): void {
    if (!this.hasCustomPositionStyle) {
      this.renderer2.removeClass(this.elementRef.nativeElement, 'position-relative');
    }
  }

  private createSpinnerElement(): HTMLDivElement {
    const spinnerContainer = this.renderer2.createElement('div');
    const spinner = this.renderer2.createElement('div');

    this.renderer2.addClass(spinnerContainer, 'spinner-container');
    this.renderer2.addClass(spinner, 'spinner-border');
    this.renderer2.addClass(spinner, 'text-' + this.theme);
    this.renderer2.appendChild(spinnerContainer, spinner);

    return spinnerContainer;
  }
}
