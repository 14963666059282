import { Pipe, PipeTransform } from '@angular/core';

import { EbfLocalizationService } from '../services/ebf-localization.service';

@Pipe({
  name: 'translate',
  pure: false,
})
export class TranslatePipe implements PipeTransform {
  constructor(private readonly ebfLocalizationService: EbfLocalizationService) {}

  public transform(value: string, params?: { [key: string]: any }, defaultValue?: string): string {
    return this.ebfLocalizationService.translate(value, params, defaultValue);
  }
}
