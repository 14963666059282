import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CheckboxModule } from 'primeng/checkbox';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { RippleModule } from 'primeng/ripple';
import { EbfLocalizationModule } from '@ebf-libs/sdk/platform/localization';
import { EbfUtilsModule } from '@ebf-libs/sdk/platform/utils';

import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { LabelControlComponent } from './view/label-control/label-control.component';
import { SwitchboxComponent } from './view/switchbox/switchbox.component';
import { TimeSelectComponent } from './view/time-select/time-select.component';
import { PendingDirective } from './directives/pending.directive';
import { PreviewIconComponent } from './view/preview-icon/preview-icon.component';
import { PreviewInputDirective } from './directives/preview-input.directive';
import { InputComponent } from './view/input/input.component';
import { ButtonComponent } from './view/button/button.component';
import { SelectButtonComponent } from './view/select-button/select-button.component';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { NgxMaskDirective } from 'ngx-mask';
import { InputSwitchModule } from 'primeng/inputswitch';

@NgModule({
  declarations: [
    NumbersOnlyDirective,
    LabelControlComponent,
    SwitchboxComponent,
    TimeSelectComponent,
    PendingDirective,
    PreviewIconComponent,
    PreviewInputDirective,
    InputComponent,
    ButtonComponent,
    SelectButtonComponent,
  ],
  imports: [
    CommonModule,
    PopoverModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    EbfLocalizationModule,
    AutoCompleteModule,
    TooltipModule,
    EbfUtilsModule,
    ButtonModule,
    PasswordModule,
    RippleModule,
    DropdownModule,
    RadioButtonModule,
    CalendarModule,
    NgxMaskDirective,
    InputSwitchModule,
  ],
  exports: [
    LabelControlComponent,
    TimeSelectComponent,
    NumbersOnlyDirective,
    SwitchboxComponent,
    PendingDirective,
    PreviewInputDirective,
    InputComponent,
    ButtonComponent,
    SelectButtonComponent,
  ],
})
export class EbfFormsModule {}
