import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfigPipe } from './pipes/config.pipe';
import { Config$Pipe } from './pipes/config$.pipe';

@NgModule({
  declarations: [ConfigPipe, Config$Pipe],
  imports: [CommonModule],
  exports: [ConfigPipe, Config$Pipe],
})
export class EbfConfigModule {}
