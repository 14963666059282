import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { delay, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  getHttpParams,
  getSanitizedBody,
  getUrlWithParams,
} from '@ebf-libs/sdk/platform/api/lib/services/utils';
import { HttpRequestConfig, MockedApiResponse } from '@ebf-libs/sdk/platform/api';
import { ApiErrorMapper } from '@ebf-libs/sdk/platform/api/lib/services/api-error-mapper.service';

@Injectable({ providedIn: 'root' })
export class ApiService {
  public apiErrorMapper: ApiErrorMapper = new ApiErrorMapper();
  constructor(private readonly httpClient: HttpClient) {}

  private requestMock<T>(response: MockedApiResponse): Observable<T> {
    const resource$: Observable<any> = response.error
      ? throwError(() => this.apiErrorMapper.mapFrom(response.error))
      : of(response.body);
    const responseDelay: number = response.delay || 1200;
    return resource$.pipe(delay(responseDelay));
  }

  public request<T = any>(config: HttpRequestConfig): Observable<T> {
    const params: HttpParams = getHttpParams(config.params);
    const body: string | Record<string, any> = getSanitizedBody(config.body);
    if (config.mockResponse) {
      console.log(
        `Fake request ${config.method.toUpperCase()}: ${getUrlWithParams(config.url, params.toString())}`,
      );
      return this.requestMock(config.mockResponse);
    }
    return this.httpClient
      .request(config.method, config.url, { ...config, params, body })
      .pipe(catchError(error => throwError(() => this.apiErrorMapper.mapFrom(error))));
  }
}
