import { Inject, Injectable, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LOCATION } from '@ng-web-apis/common';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';

import { AutoUnsubscribable } from '@ebf-libs/sdk/platform/domain';

@Injectable({
  providedIn: 'root',
})
export class EbfConfigService extends AutoUnsubscribable {
  private readonly _config$: BehaviorSubject<Record<string, any>> = new BehaviorSubject<Record<string, any>>(
    null,
  );

  public config$: Observable<Record<string, any>> = this._config$.asObservable();

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(LOCATION)
    private readonly location: Location,
  ) {
    super();
  }

  public load(configPath: string = './assets/config.json'): Observable<Record<string, any>> {
    return this.httpClient
      .get<Record<string, any>>(configPath)
      .pipe(tap((config: Record<string, any>) => this._config$.next(config)));
  }

  public get<T = string>(key: string, defaultValue?: T): T {
    const config = this._config$.getValue();

    if (!config) {
      return defaultValue ?? null;
    }

    const value = get(config, key);
    const isValueUndefined = isUndefined(value);
    const isDefaultValueUndefined = isUndefined(defaultValue);

    if (isValueUndefined && isDefaultValueUndefined && isDevMode) {
      console.warn('Unreachable property', key, config);
      return null;
    }

    return isValueUndefined ? defaultValue : value;
  }
}
