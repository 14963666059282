import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const PROTOCOL_REG_EXP: RegExp = /^http[s]?:\/\//;

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    const ignoredUrls: string[] = ['./assets'];
    const isAbsoluteUrl: boolean = PROTOCOL_REG_EXP.test(req.url);
    const isIgnoredUrl: boolean = ignoredUrls.some(item => req.url.indexOf(item) > -1);
    const url: string = isAbsoluteUrl || isIgnoredUrl ? req.url : environment.api.baseUrl + req.url;

    return next.handle(req.clone({ url }));
  }
}
