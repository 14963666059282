import get from 'lodash/get';

export const getFormattedString = (targetString: string, params: { [key: string]: any }): string => {
  const regExp: RegExp = /{([^}]+)}/g;
  const regExpMatches: string[] | null = targetString.match(regExp);
  let lastValue;

  if (!regExpMatches || !params) {
    return targetString;
  }

  regExpMatches.forEach((match, i) => {
    const paramName = match.slice(1, -1);
    let currentValue;

    if (paramName.indexOf('plural: ') === 0) {
      currentValue = getPluralFormByCount(lastValue, paramName.slice(7).split(',')).trim();
    } else {
      currentValue = get(params, paramName);
      lastValue = currentValue;
    }
    targetString = targetString.replace(regExpMatches[i], currentValue);
  });

  return targetString;
};

export const getPluralFormByCount = (count: number, pluralForms: string[]): string => {
  let n = Math.abs(count);
  n %= 100;
  if (pluralForms.length === 2) {
    return n === 1 ? pluralForms[0] : pluralForms[1];
  }
  if (n >= 5 && n <= 20) {
    return pluralForms[2];
  }
  n %= 10;
  if (n === 1) {
    return pluralForms[0];
  }
  if (n >= 2 && n <= 4) {
    return pluralForms[1];
  }
  return pluralForms[2];
};
