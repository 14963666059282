import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { lastValueFrom, switchMap } from 'rxjs';

import { tap } from 'rxjs/operators';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PrimeNGConfig } from 'primeng/api';
import { MenuModule } from 'primeng/menu';

import { EbfLocalizationModule, EbfLocalizationService } from '@ebf-libs/sdk/platform/localization';
import { EbfConfirmModule } from '@ebf-libs/sdk/platform/confirm';
import { EbfToastModule } from '@ebf-libs/sdk/platform/toast';
import { EbfConfigModule, EbfConfigService } from '@ebf-libs/sdk/platform/config';
import { EbfRouterResolveProgressbarModule } from '@ebf-libs/sdk/platform/router-resolve-progressbar';
import { HttpModule } from '@ebf/core/http/http.module';
import { LayoutsModule } from '@ebf/core/layouts/layouts.module';
import { UserService } from '@ebf/core/user/services/user.service';
import { environment } from '../../environments/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LayoutsModule,
    HttpModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    EbfConfirmModule,
    EbfToastModule,
    EbfLocalizationModule,
    EbfConfigModule,
    EbfRouterResolveProgressbarModule,
    MenuModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          ebfConfigService: EbfConfigService,
          ebfLocalizationService: EbfLocalizationService,
          userService: UserService,
        ) =>
        () => {
          const loadConfig = ebfConfigService.load();

          return lastValueFrom(
            loadConfig.pipe(
              tap(config => {
                ebfLocalizationService.availableLocales = config.availableLanguages;
                ebfLocalizationService.setLocale(config.availableLanguages[0].key);
              }),
              switchMap(() => userService.checkPreviousSession()),
            ),
          );
        },
      multi: true,
      deps: [EbfConfigService, EbfLocalizationService, UserService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (primengConfig: PrimeNGConfig) => () =>
        new Promise(resolve => {
          primengConfig.ripple = true;
          resolve(null);
        }),
      multi: true,
      deps: [PrimeNGConfig],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () =>
        new Promise(resolve => {
          const scriptElement: HTMLScriptElement = document.createElement('script');

          scriptElement.src = `https://maps.googleapis.com/maps/api/js?key=${environment.mapApiKey}&libraries=places`;
          document.head.appendChild(scriptElement);
          resolve(null);
        }),
      multi: true,
      deps: [],
    },
    {
      provide: BsDatepickerConfig,
      useFactory: (ebfLocalizationService: EbfLocalizationService) => ({
        ...new BsDatepickerConfig(),
        showWeekNumbers: false,
        dateInputFormat: ebfLocalizationService.translate('shared.dateFormat').toUpperCase(),
      }),
      deps: [EbfLocalizationService],
    },
  ],
  exports: [
    BrowserAnimationsModule,
    HttpModule,
    EbfToastModule,
    EbfRouterResolveProgressbarModule,
    ModalModule,
    LayoutsModule,
  ],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentCoreModule: CoreModule,
    private readonly modalService: BsModalService,
  ) {
    if (parentCoreModule) {
      throw new Error(
        `${parentCoreModule.constructor.name} has already been loaded. Import this module in the AppModule only.`,
      );
    }
    this.modalService.config.class = 'modal-lg';
    this.modalService.config.ignoreBackdropClick = true;
  }
}
